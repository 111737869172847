<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.template") }}
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table
        fixed-header
        height="calc(100vh - 305px)"
        class="table-padding-2"
      >
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center" style="width: 120px">
                {{ $t("labels.oa") }}
              </th>
              <th role="columnheader" class="text-center" style="width: 120px">
                {{ $t("labels.classify") }}
              </th>
              <th role="columnheader" class="text-center">
                {{ $t("labels.name") }}
              </th>
              <th role="columnheader" class="text-center" style="width: 180px">
                {{ $t("labels.template") }}
              </th>
              <th role="columnheader" class="text-center" style="width: 80px">
                {{ $t("labels.price_0") }}/tin
              </th>
              <th role="columnheader" class="text-center" style="width: 100px">
                {{ $t("labels.status") }}
              </th>
              <th role="columnheader" class="text-center" style="width: 100px">
                <v-btn
                  small
                  color="primary"
                  @click="addItem"
                  :disabled="isDisabledBtnAdd"
                >
                  <v-icon>mdi-plus</v-icon>
                  {{ $t("labels.add") }}
                </v-btn>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="text-center"
              v-for="(item, index) in items"
              :key="`c_${index}_${item.id}`"
            >
              <td>
                <span v-if="!item.editing">{{ item.customer_zalo_name }}</span>
                <v-autocomplete
                  v-else
                  class="c-input-small"
                  v-model="item.id_customer_zalo"
                  :items="accountOptions"
                  :disabled="!item.editing"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-autocomplete>
              </td>
              <td>
                <span v-if="!item.editing">{{
                  $t(`labels.zalo_template_action_type_${item.action_type}`)
                }}</span>
                <v-autocomplete
                  v-else
                  class="c-input-small"
                  v-model="item.action_type"
                  :items="actionTypeOptions"
                  :disabled="!item.editing"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-autocomplete>
              </td>
              <td>
                <span v-if="!item.editing">{{ item.name }}</span>
                <v-text-field
                  v-else
                  v-model="item.name"
                  class="c-input-small input-disabled-bold"
                  :placeholder="$t('labels.name')"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-text-field>
              </td>
              <td>
                <span
                  class="cursor-pointer primary--text text-decoration-underline"
                  @click="showImages(item.template_sample_image)"
                >
                  {{ item.template_sample_name }}
                </span>
              </td>
              <td>{{ item.price ? formatNumber(item.price) : "" }}</td>
              <td>{{ item.status_txt }}</td>
              <td>
                <template v-if="!item.editing">
                  <v-btn
                    class="mr-1"
                    x-small
                    color="warning"
                    @click="toggleEditing(item, index, true)"
                  >
                    {{ $t("labels.edit") }}
                  </v-btn>
                </template>

                <template v-else>
                  <v-btn
                    class="mr-1"
                    x-small
                    color="success"
                    @click="saveItem(item)"
                  >
                    {{ $t("labels.save") }}
                  </v-btn>
                  <v-btn
                    x-small
                    color="error"
                    @click="toggleEditing(item, index, false)"
                  >
                    {{ $t("labels.cancel") }}
                  </v-btn>
                  <br />
                  <v-btn
                    x-small
                    color="primary"
                    outlined
                    class="mt-1"
                    @click="showSampleDialog(item)"
                  >
                    {{ $t("labels.select_temp") }}
                  </v-btn>
                </template>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-dialog v-model="sampleDialog" persistent max-width="640px">
      <ZaloZnsTemplateSample
        v-if="sampleDialog"
        @cancel="hideSampleDialog"
        @selectSample="selectSample"
        :item="updatingItem"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { YES_NO_OPTIONS, ZNS_ACTION_TYPES } from "@/libs/const";
import { httpClient } from "@/libs/http";
import { api as viewerApi } from "v-viewer";

export default {
  name: "ZaloZnsConfig",
  components: {
    ZaloZnsTemplateSample: () =>
      import("@/components/account/ZaloZnsTemplateSample"),
  },
  data: () => ({
    isLoading: false,
    sampleDialog: false,
    updatingItem: {},
    accounts: [],
    items: [],
    yesNoOptions: [...YES_NO_OPTIONS],
    actionTypeOptions: [...ZNS_ACTION_TYPES],
  }),
  computed: {
    isDisabledBtnAdd() {
      const checkItem = [...this.items].find((item) => !item.id);
      return !!checkItem;
    },
    accountOptions() {
      return [...this.accounts].map((acc) => ({
        text: acc.name,
        value: acc.id,
      }));
    },
  },
  mounted() {
    this.getList();
    this.getOA();
  },
  methods: {
    showImages(image) {
      const index = 0;
      viewerApi({
        options: {
          toolbar: true,
          initialViewIndex: index,
        },
        images: [image],
      });
    },
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    cancel() {
      this.$emit("cancel");
    },
    showSampleDialog(item) {
      this.updatingItem = { ...item };
      this.sampleDialog = true;
    },
    hideSampleDialog() {
      this.updatingItem = {};
      this.sampleDialog = false;
    },
    selectSample({ id, template }) {
      const items = [...this.items];
      const index = [...items].findIndex((item) => item.id === id);
      items[index] = {
        ...items[index],
        id_zalo_zns_sample_template: template.id,
        template_sample_name: template.name,
        template_sample_image: template.image,
        template_sample_price: template.price,
        status_txt: null,
      };
      this.items = [...items];
      this.hideSampleDialog();
    },
    addItem() {
      const item = {
        editing: true,
        id: null,
        name: null,
        price: null,
        id_zalo_zns_sample_template: null,
        template_sample_name: null,
        template_sample_image: null,
        template_sample_price: null,
        status_txt: null,
      };
      this.items.unshift(item);
    },
    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },
    async saveItem(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/save-zalo-zns-template", item);
        this.isLoading = false;
        const msg = item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
        this.getList();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    async getOA() {
      try {
        const { data } = await httpClient.post("/get-all-zalo-oa", {
          status: 1,
        });
        this.accounts = [...data];
      } catch (e) {
        console.log(e);
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    async getList() {
      try {
        const { data } = await httpClient.post("/get-zalo-zns-templates");
        this.items = [...data];
      } catch (e) {
        console.log(e);
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
